import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "confirmation-modals"
    }}>{`Confirmation Modals`}</h1>
    <p>{`The common bundle provides a very flexible re-usable confirmation modal to provide you with the ability to prompt users
with a message before performing an action:`}</p>
    <p><img alt="Confirmation Example" src={require("./confirmation-example.png")} /></p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{}}>{`openConfirm(options)
* options -> object
  * title -> string = String heading to display
  * body -> string = String message to display
  * actionName -> string = Label of the confirmation button.
  * ok -> function() = function triggered when modal is confirmed.
  * cancel -> function() = function triggered when the modal is cancelled.
  * confirmationText -> string = Optional string of text the user must exactly type in before they can confirm.
  * confirmationTextLabel -> string = String describing what type of value the \`confirmationText\` is, for example slug or username.
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { openConfirm } from '@kineticdata/bundle-common';

openConfirm({
  title: 'Delete Data',
  body: 'Are you sure you want to delete the data?',
  actionName: 'Delete Data?',
  ok: () => console.log('OK! Deleting data.'),
  cancel: () => console.log('OK! Not deleting data.')
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      